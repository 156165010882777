import React from "react";
import useCeo from "../../hooks/useCEO";

export const RutaNoExistente = () => {
  useCeo({
    title: "NO ENCONTRADO. TPT",
    description: "Página no encontrada",
  });
  return (
    <>
      <div
        className="page-wrap d-flex flex-row align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <span className="display-1 d-block" style={{ color: "#ffcd00" }}>
                404
              </span>
              <div className="mb-4 lead">
                No se encontró la página que busca
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
