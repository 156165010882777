import { axiosHelper } from "../helpers/axiosHelper";

export const useCecos = () => {
  const getCecos = async (array) => {
    let resp = await axiosHelper({
      url: "cecos",
      method: "post",
      data: {ceco: array}
    });
    return resp;
  };

  return {
    getCecos,
  };
};
