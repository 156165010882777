import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation, Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext/authContext";
import { Timeout } from "../../helpers/Timeout";
import { ClikMenu } from "../../helpers/menu";
import Swal from "sweetalert2";
// iport icons
// import logoSpectrak from "./../../assets/img/logoSpectrak.png";
import TPT from "./../../assets/img/Recurso 6logo Total Protect blanco.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPowerOff,
  faBars,
  faXmark,
  faUserCircle,
  faFolderPlus,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";

import { useUnidades } from "../../hooks/useUnidades";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Loader } from "../Loader";

/* Componente para la barra de navegación */

export const Navbar = () => {
  const { user, clearSesion } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);
  const [pass, setPass] = useState("");
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => 
  {
    setShowModal(false);
    setPass("");
  }
  const handleShow = () => setShowModal(true);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const [encabezado, setEncabezado] = useState(
    document.title ? document.title : pathname
  );
  const {
    putUsuario,
  } = useUnidades();
  useEffect(() => {
    if (!show) ClikMenu();
  }, []);
  useEffect(() => {
    setEncabezado(document.title ? document.title : pathname);
  }, [pathname, document.title]);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeInput = (event) => {
    setPass(event.target.value);
  };

  const handleLogout = () => {
    clearSesion();

    navigate("/login", {
      replace: true,
    });

    localStorage.setItem("lastPath", "");
    return Swal.fire({
      title: "Hasta luego",
      text: "Se cerro correctamente la sesión",
      icon: "success",
      showConfirmButton: false,
      timer: 1500
    });
  };
  Timeout();
  const titulo = (titulo) => {
    setEncabezado(titulo);
    return encabezado;
  };
  const actualizarContrasenia = async() => {
    setLoading(true);
    let resp = await putUsuario(({
      id: user.id,
      password: pass,
      name: user.name,
      email: user.email,
      roles_id: user.roles_id,
    }));
    
    setLoading(false);

    if (!resp.ok)
      return Swal.fire({
        title: resp.msg || "No se pudo actualizar el usuario",
        text: "No pudimos realizar tus cambios debido a problemas internos, favor de contactar a soporte",
        icon: "error",
      });
    Swal.fire({
      title: "Operación realizada correctamente",
      text: "Todo fue realizado con éxito",
      icon: "success",
    });
    setPass("");
  };
  
  const permisosMenu = user.permisosMenu;
  console.log(user)
  return (
    <>
    {loading && <Loader />}
      <div className="nav-top">
        <div className="text-header header-menu" id="title-nav">
          {" "}
          {encabezado}{" "}
        </div>

        <nav
          id="vertical-menu-wrapper"
          className={`vertical-menu-wrapper vertical-menu-wrapper-add`}
        >
          <div>
            <div className="d-flex flex-column vertical-menu-logo">
              <div className="sidebar-header text-center">
                <img id="menu" src={TPT} alt="logo menu" width="85%" />
              </div>
              <span
                onClick={() => {
                  setShow(!show);
                  ClikMenu();
                }}
                id="iconoMenu"
                className="open-menu-btn padding-menu"
              >
                {show ? (
                  <FontAwesomeIcon icon={faXmark} className="pull-right" />
                ) : (
                  <FontAwesomeIcon icon={faBars} className="pull-right" />
                )}
              </span>
            </div>
          </div>
          <div>
            <ul
              className="vertical-menu nav nav-pills flex-column"
              style={{ overflowY: "auto" }}
            >
              <li id="NombreUsuario">{user.name}</li>
              <small className="white">{user.rol}</small>
                <li>
                  <Link onClick={handleShow} className="link-hover">
                    CAMBIAR CONTRASEÑA{" "}
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      className="pull-right f12"
                    />
                  </Link>
                </li>
              <li>
                <Link onClick={handleLogout} className="cerrarSesion">
                  {" "}
                  CERRAR SESIÓN{" "}
                  <FontAwesomeIcon
                    icon={faPowerOff}
                    className="pull-right f12"
                  />{" "}
                </Link>
              </li>
              <hr />
              {permisosMenu.cargarArchivos && (
                <li>
                  <NavLink
                    to="/cargar-archivos"
                    className="link-hover"
                    onClick={() => titulo("Cargar Archivos")}
                  >
                    {" "}
                    CARGAR ARCHIVOS
                    <FontAwesomeIcon
                      icon={faFolderPlus}
                      className="pull-right f12"
                    />
                  </NavLink>
                </li>
              )}
              {permisosMenu.cecos && (
                <li>
                  <NavLink
                    to="/calculadora"
                    className="link-hover"
                    onClick={() => titulo("Calculadora CECOS")}
                  >
                    {" "}
                    CALCULADORA CECOS
                    <FontAwesomeIcon
                      icon={faCalculator}
                      className="pull-right f12"
                    />
                  </NavLink>
                </li>
              )}
              {permisosMenu.administracion && (
                <li>
                  <NavLink
                    to="/usuarios"
                    className="link-hover"
                    onClick={() => titulo("Administración de usuarios")}
                  >
                    {" "}
                    USUARIOS
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      className="pull-right f12"
                    />
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </nav>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Cambiar Pass <b>{user.name}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-10">
              <input
                type={showPassword ? "text" : "password"}
                onChange={handleChangeInput}
                placeholder="Introduce tu nueva contraseña"
                className="form-control"
              ></input>
            </div>
            {pass !== "" && 
            <div className="col-2">
              <input
                type="checkbox"
                checked={showPassword}
                onClick={togglePasswordVisibility}
                disabled={pass !== "" ? false : true}
              ></input>&nbsp;
              <label>Ver</label>
            </div>
}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            disabled={pass === "" || !pass ? true : false}
            variant="primary"
            onClick={() => {
              Swal.fire({
                title: "¿Estas seguro?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "¡De acuerdo!",
                denyButtonText: `Cancelar`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  actualizarContrasenia();
                  handleClose();
                }
              });
            }}
          >
            Cambiar
          </Button>
        </Modal.Footer>
      </Modal>

      {show ? (
        <div
          className="col-12"
          style={{
            height: "200%",
            zIndex: 99,
            position: "fixed",
            backgroundColor: "rgba(255,255,255,0.5)",
            marginTop: "-5rem",
          }}
          onClick={() => {
            setShow(!show);
            ClikMenu();
          }}
        >
          &nbsp;
        </div>
      ) : (
        ""
      )}
    </>
  );
};
