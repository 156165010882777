import { useEffect, useRef } from "react";

export default function useCeo({ title = "", description = "" }) {
  const prevTitle = useRef(document.title);
  const prevMeta = useRef(document.querySelector('meta[name="description"]'));
  useEffect(() => {
    document.getElementById("title-nav").innerHTML = title;
    return title ? (document.title = title) : (document.title = prevTitle);
  }, [title]);
  useEffect(() => {
    const metaDescription = document.querySelector('meta[name="description"]');
    if (description) {
      metaDescription.setAttribute("content", description);
    }
    return () => metaDescription.setAttribute("content", prevMeta);
  }, [description]);
}
