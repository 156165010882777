export const ClikMenu = () => {
  const body = document.querySelector("body");
  const logo = document.querySelector("#menu");
  const iconMenu = document.querySelector("#iconoMenu");
  const sombra_menu = document.querySelector("#vertical-menu-wrapper");

  // Agregar y ocultar menu
  if (document.querySelector(".closed-menu")) {
    body.classList.remove("closed-menu");
  } else {
    body.classList.add("closed-menu");
  }
  // */Finaliza

  // Agregar y quita estilos de css al menu
  if (document.querySelector(".ocultar-menu")) {
    logo.classList.remove("ocultar-menu");
    iconMenu.classList.remove("pocicionar-icono-menu");
    iconMenu.classList.add("padding-menu");
  } else {
    logo.classList.add("ocultar-menu");
    iconMenu.classList.add("pocicionar-icono-menu");
    iconMenu.classList.remove("padding-menu");
  }

  if (document.querySelector(".vertical-menu-wrapper-add")) {
    sombra_menu.classList.remove("vertical-menu-wrapper-add");
    sombra_menu.classList.add("move-menu");
  } else {
    sombra_menu.classList.add("vertical-menu-wrapper-add");
    sombra_menu.classList.remove("move-menu");
  }

  // */Finaliza
};

export const Expander = () => {
  const columna1 = document.querySelector("#collapseExample");
  //
  let classes = Array.from(columna1.classList);
  if (classes.find((x) => "collapse"))
    return columna1.classList.remove("collapse");
  return columna1.classList.add("collapse");
};

export const ExpanderNotificaciones = () => {
  const columna1 = document.querySelector("#collapseNotificaciones");
  //
  let classes = Array.from(columna1.classList);
  if (classes.find((x) => "collapse"))
    return columna1.classList.remove("collapse");
  return columna1.classList.add("collapse");
};
