import { axiosHelper } from "../helpers/axiosHelper";

export const useCarga = () => {
  const postCarga = async (formData) => {
    let resp = await axiosHelper({
      url: "archivos/cargar-archivos",
      method: "post",
      data: formData,
      file: true,
    });
    return resp;
  };

  return {
    postCarga,
  };
};
