import React, { useState } from "react";
import Swal from "sweetalert2";
import useCeo from "../../hooks/useCEO";
import { useCarga } from "../../hooks/useCarga";
import { Loader } from "../../components/Loader";

export const CargaArchivos = () => {
  const [loading, setLoading] = useState(false);
  const [fileCollection, setFileCollection] = useState([]);
  const [listaUpload, setListaUpload] = useState([]);
  const { postCarga } = useCarga();

  // Inicializar el título y descripción
  useCeo({
    title: "Carga de archivos",
    description: "Carga de archivo",
  });

  // Manejar el cambio de archivos seleccionados
  const onFileChange = (e) => {
    // Asignar directamente la lista de archivos seleccionados sin duplicación
    setFileCollection(e.target.files);
  };

  // Manejar el envío del formulario
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();

    // Convertimos FileList a array para asegurar que no se repiten
    Array.from(fileCollection).forEach((file) => {
      formData.append("fileCollection", file);
    });

    const resp = await postCarga(formData);
    setLoading(false);

    if (!resp.ok) {
      return Swal.fire({
        title: "Lo siento!",
        text: resp.msg,
        icon: "info",
      });
    }

    // Actualizar la lista de archivos subidos
    const uploadedFiles = Array.from(fileCollection).map((file) => file.name);
    setListaUpload(uploadedFiles);

    Swal.fire({
      title: "¡Completado!",
      text: resp.msg,
      icon: "success",
    });
  };

  return (
    <>
      {loading && <Loader />}
      <div className="card w-75 container p-4">
        <h5 className="card-title">Subir archivos</h5>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <input
              type="file"
              name="fileCollection"
              className="form-control"
              onChange={onFileChange}
              multiple
              required
            />
          </div>
          <br />
          <h5 className="pt-1 text-end">Solo se permiten archivos xls, xlsx y cvs. Tamaño maximo de los archivos es de 30Mb.</h5>
          <br />
          <button className="btn btn-outline-success" type="submit">
            Cargar archivo(s)
          </button>
        </form>

        <ul>
          {listaUpload.map((img, index) => (
            <li key={index}>{img}</li>
          ))}
        </ul>
      </div>
    </>
  );
};
