// import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext/authContext";

export const Timeout = () => {
  const { clearSesion } = useContext(AuthContext);
  const handleOnIdle = () => {
    handleLogout();
  };

  const handleOnActive = () => {};

  const handleOnAction = () => {};

  useIdleTimer({
    timeout: 1000 * 60 * 60 * 2,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/login", {
      replace: true,
    });
    clearSesion();
  };
};
