import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext/authContext";
// import { Spinner } from "../../components/Spinner";
// import imagenes
import logoSpectrak from "../../assets/img/logo.svg";
import Swal from "sweetalert2";
import { Loader } from "../../components/Loader";

export const LoginScreen = () => {
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  const submit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let resp = await login(form.username, form.password);
    setLoading(false);
    const { ok } = resp;
    if (ok) {
      const { user } = resp;
      const { token } = user;
      localStorage.setItem("xt", token);
      window.location.href = "/cargar-archivos";
      // navigate("/programar-salida");
    } else {
      Swal.fire({ title: "Lo siento!", text: resp.msg, icon: "info" });
    }
  };
  const { username, password } = form;
  return (
    <>
      {loading && <Loader />}
      <div className="login-bg">
        <div>
          <div className="col-12 col-sm-12 col-md-9 col-xl-6 mx-auto">
            <div className="login-card animated">
              <div className="col-12 col-md-10 text-center mx-auto">
                <img
                  src={logoSpectrak}
                  alt=""
                  className="mt-3 mb-1 img-fluid"
                />
              </div>
              <form onSubmit={submit} className="col-12 mx-auto mb-2">
                <input
                  className="form-control my-5"
                  type={username.includes("@") ? "email" : "text"}
                  name="username"
                  value={username}
                  onChange={handleChange}
                  placeholder="Correo ó número de empleado"
                  required={true}
                />
                <input
                  type="password"
                  className="form-control my-3"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  placeholder="Contraseña"
                  required={true}
                />
                <input
                  type="submit"
                  value="Entrar"
                  className="mt-4 btn btn-lg btn-block w-100 mx-0 text-white"
                  style={{ background: "#0066BF" }}
                />
              </form>
              <div className="card-footer">
              <div
                className="pt-2 d-flex justify-content-end links text-white"
                title={process.env.REACT_APP_MODE}
              >
                Automización v1.0
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
