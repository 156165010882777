import axios from "axios";

export const AxiosHelper = async (
  url,
  method,
  headers,
  data,
  onSucess = () => {},
  onError = () => {}
) => {
  let url1 = "";

  switch (process.env.REACT_APP_MODE) {
    case "LOCAL":
      url1 = process.env.REACT_APP_API;
      break;
    default:
      url1 = process.env.REACT_APP_API_PRODUCTION;
      break;
  }
  var config = {
    method,
    url: url1 + url,
    headers,
    data,
  };
  console.log(url1)
console.log(config)

  return await axios(config)
    .then(function (response) {
      return onSucess(response);
    })
    .catch(function (error) {
      let message =
        error.message === "Network Error"
          ? "Tenemos problemas con nuestro servidor de datos, estamos trabajando para restablecer la conexión, intenta más tarde. O revisa tu conexión a internet."
          : error.response.data.msg
          ? error.response.data.msg
          : "Ha ocurrido un error inesperado, intenta más tarde.";
      return onError(message);
    });
};
