import React from "react";
import App from "./App";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/css/styles.css";
import "./assets/css/programarSalida.css";
import "./assets/css/gps.css";
import "./assets/css/login.css";
import "./assets/css/programarSalida.css";
import "./assets/css/cecos.css";

ReactDOM.render(<App />, document.getElementById("root"));
