import React from "react";

export const Select = ({
  name,
  value,
  onChange,
  labelText,
  placeholder,
  options = [],
  className = "",
  disabled = false,
  required = false,
}) => {
  return (
    <>
      {labelText && <label>{labelText}</label>}
      <select
        className={` ${className ? className : " form-select selectCedis"}`}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        required={required}
      >
        <option value="">
          {placeholder ? placeholder : "Selecciona una opción"}
        </option>
        {options.map((option) => {
          return (
            <option value={option.value} key={option.value}>
              {option.text}
            </option>
          );
        })}
      </select>
    </>
  );
};
