import axios from "axios";
// import Swal from "sweetalert2";
export const axiosHelper = async ({
  url = "",
  method = "get",
  data = {},
  requested = 0,
  file = false,
  showAlert = true,
}) => {
  let url1 = "";

  switch (process.env.REACT_APP_MODE) {
    case "LOCAL":
      url1 = process.env.REACT_APP_API;
      break;
    default:
      url1 = process.env.REACT_APP_API_PRODUCTION;
      break;
  }
  var config = {
    method: method,
    url: url1 + url,
    headers: {
      "Content-Type": "application/json",
      "x-token": localStorage.getItem("xt"),
    },
    data: file? data : JSON.stringify(data),
  };


  let resp = await axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      let message =
        error.message === "Network Error"
          ? "Tenemos problemas con nuestro servidor de datos, estamos trabajando para restablecer la conexión, intenta más tarde. O revisa tu conexión a internet."
          : error.response.data.msg
          ? error.response.data.msg
          : "Ha ocurrido un error inesperado, intenta más tarde.";
      if (error.message !== "Network Error")
        if (error.response.status === 503 || error.request.status === 503) {
          if (requested < 3)
            return axiosHelper({ url, method, data, requested: requested + 1 });
          message =
            "Tenemos un problema en nuestros servidores principales, estamos trabajando para corregir los problemas. ";
        }
      // if (showAlert && error.status !== 400 || error.status !== 404) Swal.fire({ title: "Lo siento!", text: message, icon: "info" });
      return { ok: false, msg: message };
    });
  return resp;
};
