import React, { createContext, useEffect, useState } from "react";
import { AxiosHelper } from "../../helpers/axios";
import jwt from "jsonwebtoken";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
  );
  useEffect(() => {
    let [ok] = verifyJWT();
    if (!ok) return;
  }, []);
  /**
   * Servicio para iniciar sesión
   * @param {text} username
   * @param {text} password
   */
  const login = async (username, password) => {
    //
    let resp = await AxiosHelper(
      `login`,
      "POST",
      {
        "Content-Type": "application/json",
      },
      JSON.stringify({
        userName: username,
        password: password,
      }),
      (resp) => {
        return onLogin(resp);
      },
      (message) => {
        return {
          ok: false,
          msg: message,
        };
      }
    );
    return resp;
  };
  const onLogin = (respuesta) => {
    const { usuario } = respuesta.data;
    // debugger;
    let menus = crearMenu(usuario.menu);

    let userLoged = {
      id: usuario.id,
      numEmpleado: usuario.users_num_empleado,
      name: usuario.name,
      rol: usuario.rol,
      roles_id: usuario.roles_id,
      email: usuario.email,
      permisosMenu: menus,
      token: usuario.token,
    };
    setUser(userLoged);
    localStorage.setItem("user", JSON.stringify(userLoged));
    localStorage.setItem("x-token", JSON.stringify(userLoged.token));
    return { ok: true, user: userLoged };
  };
  const crearMenu = (menus) => {
    //
    let permisosMenu = {};
    menus.map((x) => {
      switch (x.title) {
        case "CARGAR ARCHIVOS":
          return (permisosMenu.cargarArchivos = true);
        case "ADMINISTRACIÓN":
          return (permisosMenu.administracion = true);
        case "CECOS":
          return (permisosMenu.cecos = true);
        default:
          break;
      }
      return "";
    });
    return permisosMenu;
  };
  const clearSesion = () => {
    localStorage.removeItem("user");
    localStorage.clear();
    setUser({});
  };
  useEffect(() => {
    verifyJWT();
  }, []);
  const verifyJWT = (token = "") => {
    try {
      if (!token && localStorage.getItem("x-token")) {
        token = localStorage.getItem("x-token");
        token = token.substring(1);
        token = token.substring(0, token.length - 1);
      }
      if (!token) return [false];
      const { userId, rolId } = jwt.verify(
        token,
        process.env.REACT_APP_JWT_KEY
      );

      return [true, userId, rolId];
    } catch (error) {
      clearSesion();
      window.location.href = "/login";
      return [false, null];
    }
  };
  const renewToken = async (token = "") => {
    let usuarioAUX = JSON.parse(localStorage.getItem("user"));
    usuarioAUX.token = token;
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(usuarioAUX));

    return setUser({ ...user, usuarioAUX });
  };
  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        clearSesion,
        verifyJWT,
        setUser,
        renewToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
