import React, { useState, useEffect } from "react";
import useCeo from "../../hooks/useCEO";
import { useCecos } from "../../hooks/useCecos";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { Loader } from "../../components/Loader";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faPaperPlane,
  faTrashCanArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import TPT from "./../../assets/img/TPT_logo1.png";

import { toast, ToastContainer } from "react-toastify";

const darkTheme = createTheme({
  typography: {
    fontSize: 13, // Ajusta el tamaño de la letra aquí
  },
  // palette: {
  //   mode: 'dark',
  //   background: {
  //     default: '#303030',
  //     paper: '#424242',
  //   },
  //   text: {
  //     primary: '#fff',
  //   },
  // },
  components: {
    MuiTableCell: {
      styleOverrides: {
        // root: {
        //   color: '#ffffff',
        //   backgroundColor: '#424242',
        // },
        head: {
          backgroundColor: "#C0D2EC",
          color: "#000",
        },
      },
    },
  },
  // MuiTypography: {
  //   styleOverrides: {
  //     root: {
  //       color: '#ffffff', // Cambiar el color del texto en general
  //     },
  //     h6: {
  //       color: '#ff4081', // Cambiar el color del título específicamente
  //     },
  //   },
  // },
});

export const Cecos = () => {
  useCeo({
    title: "Calculadora CECOS",
    description: "descripcion de los CECOS",
  });

  const [show, setShow] = useState(false);
  const { getCecos } = useCecos();
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setShow(false);
    reset();
  };
  const handleShow = () => setShow(true);
  const [data, setData] = useState([]);
  const [ceco, setCeco] = useState("");
  const [arrayCeco, setArryaCeco] = useState([]);
  const [resultado, setResultado] = useState([]);

  const handleChange = (event) => {
    if (event.target.name === "ceco") {
      setCeco(event.target.value);
    } else {
      setArryaCeco(event.target.value);
    }
  };

  const reset = () => {
    setCeco("");
    setArryaCeco([]);
  };

  // Este useEffect se usa para actualizar `data` cada vez que `resultado` cambia.
  useEffect(() => {
    const array = resultado.map(
      (
        {
          id,
          idcc,
          nombre,
          abierto,
          estatus,
          dnegocio,
          pais,
          dsnegocio,
          nivel1,
          nivel2,
          nivel3,
          nivel4,
          entidad,
          nresponsable,
          nresponsablea,
        },
        index
      ) => [
        index + 1,
        idcc,
        nombre,
        abierto,
        estatus,
        dnegocio,
        pais,
        dsnegocio ? dsnegocio : "NO APLICA",
        nivel1 ? nivel1 : "NO APLICA",
        nivel2 ? nivel2 : "NO APLICA",
        nivel3 ? nivel3 : "NO APLICA",
        nivel4 ? nivel4 : "NO APLICA",
        entidad,
        nresponsable,
        nresponsablea,
        <FontAwesomeIcon
          icon={faTrashAlt}
          className="fa-2x text-danger cursor"
          onClick={() => {
            deleteItem(id, nombre);
          }}
          title="Quitar de la lista"
        />,
      ]
    );
    setData(array);
  }, [resultado]);

  const buscarCecos = async () => {
    setLoading(true);
    let arreglo = [];
    if (arrayCeco.length > 0) {
      arreglo = arrayCeco.split(",");
      if (!Array.isArray(arreglo)) {
        setLoading(false);
        reset();
        return Swal.fire({
          title: "¡Lo siento!",
          text: "Los números de CECO no estan en el formato solicitado",
          icon: "info",
        });
      }
    }

    const cecos = await getCecos(ceco ? [ceco] : arreglo);
    if (!cecos.ok) {
      setLoading(false);
      reset();
      return toast.error(`${cecos.msg}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      const newResultado = [
        ...resultado, // Copia todos los elementos del arreglo anterior
        ...cecos.data, // Añade el nuevo elemento
      ];
      setResultado(newResultado);
      setLoading(false);
      reset();
      return toast.success(`${cecos.msg}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const deleteItem = (id, nombre) => {
    const newResultado = resultado.filter((x) => x.id !== id);
    setResultado(newResultado);
    setLoading(false);
    reset();
    return toast.info(`Se quito de la lista ${nombre}`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const columns = [
    {
      name: '#',
      label: '#',
      options: {
        sort: true,
        sortDirection: 'desc',  // Para ordenar de manera descendente
      },
    },
    // "#",
    "Idcc",
    "Nombre",
    "Abierto a facturación",
    "Estatus",
    "Empresa",
    "País",
    "Unidad de negocio",
    "Sub nivel 1",
    "Sub nivel 2",
    "Sub nivel 3",
    "Sub nivel 4",
    "Entidad",
    "Responsable",
    "Responsable alterno",
    "Opciones",
  ];

  const options = {
    responsive: "standard",
    setCellProps: () => ({
      style: { textAlign: "center" }, // Centrando el texto
    }),
    selectableRows: "none",
    downloadOptions: {
      filename: "cecos.csv",
      // separator: ";",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
      CharacterData: "utf-8",
      CharSet: "utf-8",
    },
    onDownload: (buildHead, buildBody, columnsTable, data) => {
      return "\uFEFF" + buildHead(columnsTable) + buildBody(data);
    },
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Página siguiente",
        previous: "Página anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtro de tabla",
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas de tabla",
      },
      selectedRows: {
        text: "Fila(s) seleccionada(s)",
        delete: "Remover",
        deleteAria: "Fila seleccionada removida",
      },
    },
  };

  return (
    <>
      {loading && <Loader />}
      <main className="container">
        <div className="text-center">
          <img
            src={TPT}
            className="mb-3 mt-2"
            alt="logoTPT"
            width={"350px"}
          ></img>
        </div>
        <div className="row">
          <div className="col-3">
            <FloatingLabel
              controlId="floatingInput"
              label="Numero CECO"
              className="text-dark"
            >
              <Form.Control
                style={{
                  borderRadius: "10px",
                  border: "1px",
                  solid: "#ccc",
                }}
                type="number"
                min={1}
                //   style={{ height: "300px", width: "215%" }}
                //   as="textarea"
                name="ceco"
                value={ceco}
                onChange={handleChange}
                placeholder={"['','']"}
              />
            </FloatingLabel>
          </div>
          <div className="col-3 p-2">
            <button
              disabled={ceco ? false : true}
              className="btn btn-primary btn-block"
              onClick={buscarCecos}
              title="Buscar de a un elemento"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>{" "}
              Buscar
            </button>
          </div>
          <div className="col-3 p-2">
            <button
              disabled={resultado.length === 0 ? true : false}
              title="Limpiar busqueda"
              className="btn btn-secondary btn-block"
              onClick={() => {
                setResultado([]);
                setData([]);
                reset();
              }}
            >
              <FontAwesomeIcon icon={faTrashCanArrowUp} /> Resetear busqueda
            </button>
          </div>
          <div className="col-3 p-2">
            <button
              className="btn btn-success btn-block"
              title="Podras agregar mas de un CECO a buscar, mejor accesibilidad"
              onClick={handleShow}
            >
              <FontAwesomeIcon icon={faMagnifyingGlassPlus}></FontAwesomeIcon>{" "}
              Buscar varios CECOs
            </button>
          </div>
        </div>
      </main>
      <br />
      <section className="mt-2 container-fluid">
        <ThemeProvider theme={darkTheme}>
          <MUIDataTable
            // title={<Typography variant="h6">PT Calculadora de Agrupación de CECOS | Nueva Estructura 202</Typography>}
            title={
              "PT Calculadora de Agrupación de CECOS | Nueva Estructura 2024"
            }
            data={data}
            columns={columns}
            options={options}
            onDownload={(buildHead, buildBody, columns, data) => {
              return "\uFEFF" + buildHead(columns) + buildBody(data);
            }}
          />
        </ThemeProvider>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="bg-secondary" closeButton>
          <Modal.Title className="text-white">Buscar varios CECOs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel
            controlId="floatingInput"
            label="Números CECOs (Sepados por comas ,)"
            // className="mb-3"
          >
            <Form.Control
              type="number"
              // style={{ height: "300px", width: "215%" }}
              as="textarea"
              name="numerosSerie"
              value={arrayCeco}
              onChange={handleChange}
              placeholder={"['','']"}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={arrayCeco.length === 0 ? true : false}
            onClick={() => {
              buscarCecos();
              handleClose();
            }}
          >
            <FontAwesomeIcon icon={faPaperPlane}></FontAwesomeIcon> Enviar
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};
