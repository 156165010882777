import React, { useContext } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Navbar } from "./../components/Template/NavBar";

import { AuthContext } from "../context/authContext/authContext";
import { RutaNoExistente } from "../pages/landing/RutaNoExistente";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CargaArchivos } from "../pages/landing/CargaArchivos";
import { Usuarios } from "../pages/landing/Usuarios";
import { Cecos } from "../pages/landing/Cecos";

export const DashboardRoutes = () => {
  const { user } = useContext(AuthContext);
  const { pathname } = useLocation();

  const RedirectToIndex = () => {
    return <Navigate to="/login"></Navigate>;
  };

  if (pathname === "/") return <Navigate to="/cargar-archivos"></Navigate>;
  // console.log(user);
  return (
    <>
      <Navbar />

      <div className="pt-5 mt-4">
        <Routes>
        {user.permisosMenu.cargarArchivos ? (
          <Route exact path="/cargar-archivos" element={<CargaArchivos />} />
        ): (
          RedirectToIndex
        )}
        {user.permisosMenu.administracion ? (
          <Route exact path="/usuarios" element={<Usuarios />} />
        ): (
          RedirectToIndex
        )}
        {user.permisosMenu.cecos ? (
          <Route exact path="/calculadora" element={<Cecos />} />
        ): (
          RedirectToIndex
        )}
          <Route exact path={pathname} element={<RutaNoExistente />} />
        </Routes>
      </div>

    </>
  );
};
