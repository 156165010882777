import React from "react";
import "../assets/css/fullLoader.css";

export const Loader = () => {
  return (
    <div style={{ zIndex: 99999999999999999999999999999999999999999999999999 }}>
      <div id="preloader2" className="text-center">
        <img
          src={require("../assets/img/validacion-loader.gif")}
          className="img-fluid"
          alt=""
        ></img>
      </div>
    </div>
  );
};