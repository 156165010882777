import React from "react";

export const Input = ({
  name,
  value,
  id,
  placeholder,
  labelText,
  type,
  classNames,
  onChange,
  disabled = false,
  required = false,
  onBlur = () => {},
}) => {
  return (
    <>
      {labelText && <label htmlFor={id}>{labelText}</label>}
      <input
        className={`form-control ${classNames} mt-2`}
        type={type}
        value={value}
        id={id}
        placeholder={placeholder ? placeholder : "Escribe aquí"}
        onChange={onChange}
        name={name}
        required={required}
        disabled={disabled}
        onBlur={onBlur}
      ></input>
    </>
  );
};
