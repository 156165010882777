import React, { useEffect } from "react";
import { AuthProvider } from "./context/authContext/authContext";
import { AppRouter } from "./routers/AppRouter";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const App = () => {
  useEffect(() => {
    moment.locale("en"); // default the locale to English
    var localLocale = moment();
    localLocale.locale("es"); // set this instance to use French
    localLocale.format("LLLL"); // dimanche 15 juillet 2012 11:01
    moment().format("LLLL"); // Sunday, July 15 2012 11:01 AM
  }, []);

  return (
    // Provedor de autenticación
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  );
};

export default App;
